import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectLookups(Vue, esTecnico, esComercial, esVigilante, idpuestoServicioServicio) {
    let apiCalls = []
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo.addGT('estado', 0)
    apiCalls.push({ name: 'selectFuerzaSeguridad', method: 'fuerzaSeguridad.select', params: { filter: apiFilterEstadoActivo } })
    const apiFilterTnovedad = new APIFilter()
    apiFilterTnovedad
      .addGT('estado', 0)
      .addExact('idpuesto_servicio_servicio', idpuestoServicioServicio)
    apiCalls.push({ name: 'selectTnovedad', method: 'tnovedad.select', params: { filter: apiFilterTnovedad } })
    const apiFilterVisualizacion = new APIFilter()
    apiFilterVisualizacion.setOperator(API_FILTER_OPERATORS.OR)
    if (esTecnico) {
      apiFilterVisualizacion.addExact('visible_movilidad_sat', esTecnico)
    }
    if (esComercial) {
      apiFilterVisualizacion.addExact('visible_movilidad_vigilancia', esComercial)
    }
    if (esVigilante) {
      apiFilterVisualizacion.addExact('visible_movilidad_sat', esVigilante)
    }
    const apiFilterFicheros = new APIFilter()
    apiFilterFicheros.addNestedFilter(apiFilterVisualizacion)
    apiCalls.push({ name: 'selectTfichero', method: 'tfichero.select', params: { filter: apiFilterFicheros } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectFormData (Vue, id) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('idlparte_novedad', id)
    const resp = await Vue.$api.call('lparteNovedad.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
  async selectPuntoCodigoScanner (Vue, codigoScanner, idpuestoServicio) {
    const apiFilter = new APIFilter()
    apiFilter
      .addGT('estado', 0)
      .addExact('codigo_scanner', codigoScanner)
      .addExact('idpuesto_servicio', idpuestoServicio)
    const respPunto = await Vue.$api.call('punto.select', { filter: apiFilter })
    return respPunto.data.result.dataset
  },
}
