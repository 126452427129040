<template>
  <b10-base>
    <ValidationObserver
      v-for="step in steps"
      v-slot="slotObserverProps"
      :key="step.id"
    >
      <v-stepper-step
        :step="step.id"
        editable
        :rules="[value => !slotObserverProps.validated || slotObserverProps.valid]"
      >
        {{ step.title }}
        <small>{{ step.subtitle }}</small>
      </v-stepper-step>
      <v-stepper-content
        :step="step.id"
      >
        <template
          v-for="stepField in step.formFields"
        >
          <ValidationProvider
            v-slot="{ errors }"
            :key="stepField.name"
            :name="stepField.name"
            :rules="formRules[stepField.name]"
          >
            <v-text-field
              v-if="stepField.tipo === TDATO_TIPO_DATO.tipos.texto"
              v-model="form[stepField.name]"
              clearable
              flat
              :label="stepField.label"
              :error-messages="errors"
              :messages="stepField.messages"
            />
            <v-text-field
              v-else-if="stepField.tipo === TDATO_TIPO_DATO.tipos.entero || stepField.tipo === TDATO_TIPO_DATO.tipos.decimal"
              v-model.number="form[stepField.name]"
              type="number"
              clearable
              flat
              :label="stepField.label"
              :error-messages="errors"
              :messages="stepField.messages"
            />
            <b10-date-picker
              v-else-if="stepField.tipo === TDATO_TIPO_DATO.tipos.fecha"
              v-model="form[stepField.name]"
              :title="stepField.label"
              :error-messages="errors"
              :messages="stepField.messages"
            />
            <b10-time-picker
              v-else-if="stepField.tipo === TDATO_TIPO_DATO.tipos.hora"
              v-model="form[stepField.name]"
              :title="stepField.label"
              :error-messages="errors"
              :messages="stepField.messages"
            />
            <b10-date-time-picker
              v-else-if="stepField.tipo === TDATO_TIPO_DATO.tipos.fechaHora"
              v-model="form[stepField.name]"
              :title="stepField.label"
              :error-messages="errors"
              :messages="stepField.messages"
            />
            <b10-autocomplete
              v-else-if="stepField.tipo === TDATO_TIPO_DATO.tipos.listaValores"
              v-model="form[stepField.name]"
              :items="stepField.valores"
              item-value="descripcion"
              item-text="descripcion"
              :label="stepField.label"
              clearable
              :error-messages="errors"
              :messages="stepField.messages"
            />
            <b10-firma
              v-else-if="stepField.tipo === TDATO_TIPO_DATO.tipos.firma"
              v-model="form[stepField.name]"
              :name="stepField.name"
              :label="stepField.label"
              :error-messages="errors"
              :messages="stepField.messages"
              :validation-rules="formRules[stepField.name]"
            />
          </ValidationProvider>
        </template>
      </v-stepper-content>
    </ValidationObserver>
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import { TDATO_TIPO_DATO } from '@/utils/consts'
import Data from './TipoDatoStepFormData'
import _ from '@/utils/lodash'

export default {
  mixins: [formMixin],
  props: {
    formStepId: {
      type: Number,
      default: 1,
    },
    idmtdato: {
      type: Number,
      default: 0,
    },
    validarRequeridos: {
      type: Boolean,
      default: true,
    }
  },
  data () {
    return {
      steps: [],
      currentStep: 1,
      TDATO_TIPO_DATO,
      form: {},
      formRules: {},
      formFieldsLoaded: false,
    }
  },
  watch: {
    idmtdato (value) {
      this.crearDatosFormFields(value)
    },
    formStepId () {
      this.crearDatosFormFields(this.idmtdato)
    },
    form: {
      // OJO: debe definirse como `function` no una función arrow: https://stackoverflow.com/a/42242803
      handler: function () {
        this.emitChangeValues()
      },
      deep: true,
    },
  },
  methods: {
    emitChangeValues () {
      this.$emit('change-component-values', this.form)
    },
    async crearDatosFormFields (idmtdato) {
      this.steps = []
      this.currentStep = 1
      this.form = {}
      this.formRules = {}
      this.formFieldsLoaded = false
      const selectLookupsDatos = await Data.selectLookupsDatos(this, idmtdato)
      const tdatosDataset = selectLookupsDatos.data.selectTdato.result.dataset
      const tgrupos = selectLookupsDatos.data.selectTgrupoTdato.result.dataset
      const tdatosValores = selectLookupsDatos.data.selectTdatoValor.result.dataset
      const idGruposTdatos = _.uniqBy(_.map(tdatosDataset, 'idtgrupo_tdato'))
      let stepId = this.formStepId
      let lastStep = {
        idtgrupoTdato: -1,
        id: stepId,
      }
      let fieldname = null
      for (const i in idGruposTdatos) {
        let tipoGrupo = _.find(tgrupos, {'idtgrupo_tdato': idGruposTdatos[i]})
        const idtgrupoTdato = tipoGrupo?.idtgrupo_tdato ?? null
        if (idtgrupoTdato !== lastStep.idtgrupoTdato) {
          let descGrupo = tipoGrupo?.descripcion ?? 'Sin agrupación';
          lastStep = {
            id: stepId,
            idtgrupoTdato: idtgrupoTdato,
            title: descGrupo,
            subtitle: 'Datos personalizados',
            formFields: [],
          }
          this.steps.push(lastStep)
          stepId += 1
        }
        const tdatosPorGrupo = _.filter(tdatosDataset, { 'idtgrupo_tdato': idtgrupoTdato })
        for (const tdato of tdatosPorGrupo) {
          // TODO: Mejorar esto para no tener tantos if-else-if
          if (TDATO_TIPO_DATO.tiposNumber.includes(tdato.idtipo_tdato)) {
            fieldname = `${TDATO_TIPO_DATO.formFieldNames.tiposNumber}${tdato.idtdato}`
            this.createMaxOracleNumberFormRules(fieldname, TDATO_TIPO_DATO.maxLength.tiposNumber)
          } else if (tdato.idtipo_tdato === TDATO_TIPO_DATO.tipos.hora) {
            fieldname = `${TDATO_TIPO_DATO.formFieldNames.hora}${tdato.idtdato}`
          } else if (tdato.idtipo_tdato === TDATO_TIPO_DATO.tipos.fecha) {
            fieldname = `${TDATO_TIPO_DATO.formFieldNames.fecha}${tdato.idtdato}`
          } else if (tdato.idtipo_tdato === TDATO_TIPO_DATO.tipos.fechaHora) {
            fieldname = `${TDATO_TIPO_DATO.formFieldNames.fechaHora}${tdato.idtdato}`
          } else if (tdato.idtipo_tdato === TDATO_TIPO_DATO.tipos.firma) {
            fieldname = `${TDATO_TIPO_DATO.formFieldNames.firma}${tdato.idtdato}`
          } else {
            fieldname = `${TDATO_TIPO_DATO.formFieldNames.tiposString}${tdato.idtdato}`
            this.createMaxFormRules(fieldname, TDATO_TIPO_DATO.maxLength.tiposString)
          }
          if (this.validarRequeridos) {
            if (tdato.requerido) {
              this.createRequiredFormRules(fieldname)
            }
          } else if (!this.formRules[fieldname]) {
            // form rule vacía para crear siempre el ValidationProvider
            this.$set(this.formRules, fieldname, {})
          }
          this.$set(this.form, fieldname, null)
          // agregar control
          const stepField = {
            name: fieldname,
            label: tdato.descripcion,
            tipo: tdato.idtipo_tdato,
            messages: tdato.requerido ? ['Campo requerido'] : null,
            valores: _.filter(tdatosValores, { idtdato: tdato.idtdato }),
          }
          lastStep.formFields.push(stepField)
        }
      }
    },
  }
}
</script>