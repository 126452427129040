import { APIFilter } from '@/utils/api'

export default {
  async selectLookupsDatos (Vue, idmtdato) {
    let apiCalls = []
    const apiFilterMtdato = new APIFilter()
    apiFilterMtdato.addGT('estado', 0)
    apiFilterMtdato.addExact('idmtdato', idmtdato)
    apiCalls.push({ name: 'selectMtdato', method: 'mtdato.select', params: { filter: apiFilterMtdato } })
    apiCalls.push({ name: 'selectTdato', method: 'tdato.select', params: { filter: apiFilterMtdato } })
    apiCalls.push({ name: 'selectTgrupoTdato', method: 'tgrupoTdato.select', params: { filter: apiFilterMtdato } })
    apiCalls.push({ name: 'selectTdatoValor', method: 'tdatoValor.select', params: { filter: apiFilterMtdato } })
    return await Vue.$api.batchCall(apiCalls)
  },
}