<template>
  <b10-base>
    <v-stepper
      vertical
      non-linear
      :value="currentStep"
      class="elevation-0"
    >
      <!-- General -->
      <ValidationObserver
        v-slot="slotObserverProps"
      >
        <v-stepper-step
          :step="steps.general.id"
          editable
          :rules="[value => !slotObserverProps.validated || slotObserverProps.valid]"
        >
          {{ steps.general.title }}
          <small>{{ steps.general.subtitle }}</small>
        </v-stepper-step>
        <v-stepper-content
          :step="steps.general.id"
        >
          <ValidationProvider
            v-slot="{ errors }"
            name="fnovedad"
            :rules="formRules.fnovedad"
          >
            <b10-date-time-picker
              v-model="form.fnovedad"
              title="Fecha y hora"
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="tnovedad"
            :rules="formRules.tnovedad"
          >
            <b10-autocomplete
              v-model="form.tnovedad"
              :items="tnovedades"
              item-text="descripcion"
              return-object
              label="Tipo de novedad"
              clearable
              :error-messages="errors"
              @change="tnovedadSelected"
            />
          </ValidationProvider>
          <b10-autocomplete
            v-model="form.idfuerza_seguridad"
            :items="fuerzasSeguridad"
            item-value="idfuerza_seguridad"
            item-text="descripcion"
            label="Notificado a"
            clearable
          />
          <b10-textarea
            v-model="form.descripcion"
            label="Descripción"
            :validation-rules="formRules.descripcion"
          />
          <ValidationProvider
            v-slot="{ errors }"
            name="punto"
            :rules="formRules.punto"
            mode="lazy"
          >
            <v-text-field
              v-model="form.punto"
              class="input-punto"
              label="Punto"
              type="text"
              autocomplete="off"
              clearable
              :error-messages="errors"
              @change="changePunto"
            >
              <template #append-outer>
                <v-icon
                  v-if="hasNFC"
                  class="mt-1 ml-1"
                  @click="clickScanNFC"
                >
                  {{ $vuetify.icons.values.nfc }}
                </v-icon>
                <v-icon
                  class="mt-1 ml-3"
                  @click="clickQR"
                >
                  {{ $vuetify.icons.values.qr }}
                </v-icon>
              </template>
            </v-text-field>
          </ValidationProvider>
          <v-text-field
            v-model.number="form.latitud"
            type="number"
            label="Latitud"
            disabled
          />
          <v-text-field
            v-model.number="form.longitud"
            type="number"
            label="Longitud"
            disabled
          />
          <v-btn
            v-if="form.latitud && form.longitud"
            block
            color="secondary"
            dark
            @click.stop="clickIrMapa(form.latitud, form.longitud)"
          >
            <v-icon
              left
            >
              {{ $vuetify.icons.values.mapLocation }}
            </v-icon> Localización
          </v-btn>
          <b10-qr-reader
            :showing.sync="showingDialogs.scanQR"
            title="Leer código QR"
            @decode="decodeQR"
          />
        </v-stepper-content>
      </ValidationObserver>
      <!-- Ficheros -->
      <ValidationObserver
        v-if="isInsert"
        v-slot="slotObserverProps"
      >
        <v-stepper-step
          :step="steps.ficheros.id"
          editable
          :rules="[value => !slotObserverProps.validated || slotObserverProps.valid]"
        >
          {{ steps.ficheros.title }}
          <small>{{ steps.ficheros.subtitle }}</small>
        </v-stepper-step>
        <v-stepper-content
          :step="steps.ficheros.id"
        >
          <ValidationProvider
            v-slot="{ errors }"
            name="ficheros"
            :rules="formRules.ficheros"
          >
            <v-file-input
              v-model="form.ficheros"
              label="Ficheros"
              show-size
              prepend-icon="attach_file"
              chips
              multiple
              :accept="stringTypeExtensions"
              :append-outer-icon="$vuetify.icons.values.camera"
              :disabled="capturedImage"
              :error-messages="errors"
              @change="filesChange"
              @click:append-outer="clickCamera"
            />
          </ValidationProvider>
          <b10-camera
            :showing.sync="showingCamera"
            :device-id="devicesCameraDeviceId"
            @click-shutter="clickShutter"
            @change-deviceid="changeCamera"
          />
          <ValidationProvider
            v-slot="{ errors }"
            name="idtfichero"
            :rules="formRules.idtfichero"
          >
            <b10-autocomplete
              v-model="form.idtfichero"
              :items="tficheros"
              item-value="idtfichero"
              item-text="descripcion"
              label="Tipo de fichero"
              clearable
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-stepper-content>
      </ValidationObserver>
      <!-- Datos -->
      <tipo-dato-step-form
        v-if="isInsert"
        v-show="idmtdato"
        :idmtdato="idmtdato"
        :form-step-id="maxStepID + 1"
        @change-component-values="changeTipoDatoStepFormData"
      />
    </v-stepper>
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import TipoDatoStepForm from '@/pages/components/TipoDatoStepForm'
import _ from '@/utils/lodash'
import { getLatLongURL } from '@/utils/maps'
import { currentDateTime } from '@/utils/date'
import { hasNFC, scanNFC } from '@/utils/nfc'
import { TDATO_NOVEDAD, TYPE_EXTENSIONS } from '@/utils/consts'
import { extend } from 'vee-validate'
import { sync } from 'vuex-pathify'
import Data from './LparteNovedadFormData'

export default {
  components: {
    TipoDatoStepForm
  },
  mixins: [formMixin],
  props: {
    geolocalizacion: {
      type: Object,
      default: () => {},
    },
    puntoObligatorio: {
      type: Boolean,
      default: false,
    },
    idpuestoServicio: {
      type: [Number, String],
      default: null,
    },
    idpuestoServicioServicio: {
      type: [Number, String],
      required: true
    },
    esTecnico: {
      type: Boolean,
    },
    esComercial: {
      type: Boolean,
    },
    esVigilante: {
      type: Boolean,
    },
  },
  data () {
    return {
      tnovedades: [],
      fuerzasSeguridad: [],
      tdatos: [],
      tficheros: [],
      puestoServicioServicio: [],
      form: {
        fnovedad: currentDateTime(),
        tnovedad: null,
        idfuerza_seguridad: null,
        informe_cliente: false,
        descripcion: '',
        latitud: null,
        longitud: null,
        punto: '',
        idpunto: null,
        datosNovedad: [],
        ficheros: [],
        idtfichero: null,
      },
      formRules: {
        fnovedad: { required: true },
        tnovedad: { required: true },
        descripcion: { required: true },
        punto: { required: this.puntoObligatorio, existePunto: this.puntoObligatorio },
        idtfichero: { required_if: { target: 'ficheros' } },
        ficheros: { tamanioFicheroAdjunto: true, tipoFicheroValido: true },
      },
      stringTypeExtensions: `${TYPE_EXTENSIONS.join(',')}`,
      geolocation: null,
      tiposTdatoNovedad: TDATO_NOVEDAD,
      hasNFC: hasNFC(),
      showingDialogs: {
        scanQR: false,
      },
      currentStep: 1,
      steps: {
        general: {
          id: 1,
          title: 'General',
          subtitle: 'Datos generales',
        },
        ficheros: {
          id: 2,
          title: 'Adjuntos',
          subtitle: 'Ficheros adjuntos y fotografías',
        },
      },
      idmtdato: null,
      capturedImage: false,
      showingCamera: false,
    }
  },
  computed: {
    devicesCameraDeviceId: sync('devices/cameraDeviceId'),
    maxStepID () {
      return Math.max(..._.map(this.steps, 'id'))
    },
  },
  watch: {
    geolocalizacion: {
      handler: function (newValue) {
        if (newValue) {
          this.$set(this.form, 'latitud', newValue.lat)
          this.$set(this.form, 'longitud', newValue.lng)
        }
      },
      deep: true,
    },
    puntoObligatorio (value) {
      this.formRules.punto.required = value
      this.formRules.punto.existePunto = value
    },
  },
  async created () {
    const resp = await Data.selectLookups(this, this.esTecnico, this.esComercial, this.esVigilante, this.idpuestoServicioServicio)
    this.tnovedades = resp.data.selectTnovedad.result.dataset
    this.fuerzasSeguridad = resp.data.selectFuerzaSeguridad.result.dataset
    this.tficheros = resp.data.selectTfichero.result.dataset
    extend('existePunto', {
      message: 'El punto no existe o no pertenece al puesto de servicio',
      validate: async (value) => {
        // volver a buscar el idpunto según el código scanner porque:
        // - se dispara el changePunto que asigna form.idpunto
        // - pero se puede ejecutar esta validación antes de que se haga la asignación de form.idpunto
        const datasetPuntos = await Data.selectPuntoCodigoScanner(this, value, this.idpuestoServicio)
        if (datasetPuntos.length > 0) {
          this.$set(this.form, 'idpunto', datasetPuntos[0].idpunto)
          return true
        } else {
          this.$set(this.form, 'idpunto', null)
          return false
        }
      }
    })
    extend('tamanioFicheroAdjunto', {
      message: 'El fichero es demasiado grande',
      validate: async (value) => {
        if (this.tamanioMaxAdjuntoMB > 0) {
          return fileSizeValidation.validate(value, { size: this.tamanioMaxAdjuntoMB * 1024 })
        } else {
          return true
        }
      }
    })
    // Validación para el tipo de fichero
    extend('tipoFicheroValido', {
      message: 'Tipo de fichero no permitido',
      validate: (value) => {
        return _.filter(value, (fileValue) => !TYPE_EXTENSIONS.includes(fileValue.type)).length === 0
      }
    })
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      this.$set(this.form, 'fnovedad', formData.fnovedad)
      const tnovedad = _.find(this.tnovedades, { idtnovedad: formData.idtnovedad })
      this.$set(this.form, 'tnovedad', tnovedad)
      // forzar evento
      this.tnovedadSelected(tnovedad)
      this.$set(this.form, 'idfuerza_seguridad', formData.idfuerza_seguridad)
      this.$set(this.form, 'informe_cliente', formData.informe_cliente)
      this.$set(this.form, 'descripcion', formData.descripcion)
      this.$set(this.form, 'latitud', formData.latitud)
      this.$set(this.form, 'longitud', formData.longitud)
      this.$set(this.form, 'idpunto', formData.idpunto)
      this.$set(this.form, 'punto', formData.punto_codigo_scanner)
      this.$set(this.form, 'idpuesto_servicio_servicio', formData.idpuesto_servicio_servicio)
    },
    async tnovedadSelected (value) {
      if (value) {
        this.$set(this, 'idmtdato', value.idmtdato)
        // fuerza de seguridad por defecto
        if (value.idfuerza_seguridad) {
          this.$set(this.form, 'idfuerza_seguridad', value.idfuerza_seguridad)
        } else {
          this.$set(this.form, 'idfuerza_seguridad', null)
        }
        // informe_cliente
        this.$set(this.form, 'informe_cliente', value.informe_cliente)
      } else {
        this.$set(this, 'idmtdato', null)
        this.$set(this.form, 'idfuerza_seguridad', null)
        this.$set(this.form, 'informe_cliente', false)
      }
    },
    tdatoObligatorioRule (item) {
      if (item.obligatorio) {
        return [
          v => !!v || 'Campo requerido'
        ]
      } else {
        return []
      }
    },
    clickIrMapa (lat, lng) {
      window.open(getLatLongURL(lat, lng), '_blank')
    },
    async changePunto (value) {
      if (value) {
        const datasetPuntos = await Data.selectPuntoCodigoScanner(this, value, this.idpuestoServicio)
        if (datasetPuntos.length > 0) {
          this.$set(this.form, 'idpunto', datasetPuntos[0].idpunto)
        } else {
          this.$set(this.form, 'idpunto', null)
        }
      } else {
        this.$set(this.form, 'idpunto', null)
      }
    },
    clickQR () {
      this.showingDialogs.scanQR = !this.showingDialogs.scanQR
    },
    decodeQR (value) {
      if (value) {
        this.$set(this.form, 'punto', value)
        this.changePunto(value)
      }
    },
    async clickScanNFC () {
      this.$loading.showManual('Esperando lectura de etiqueta NFC...', true)
      try {
        const tag = await scanNFC()
        if (tag && tag.tagID) {
          this.$set(this.form, 'punto', tag.tagID)
          this.changePunto(tag.tagID)
          this.$alert.showSnackbarSuccess('Etiqueta NFC leída')
        } else {
          this.$alert.showSnackbarError('No se puede leer la etiqueta NFC')
        }
      } finally {
        this.$loading.hide()
      }
    },
    async changeTipoDatoStepFormData(data) {
      this.$set(this.form, 'datosNovedad', data)
    },
    filesChange (files) {
      this.$set(this.form, 'ficheros', files)
    },
    clickCamera () {
      this.showingCamera = true
    },
    changeCamera (value) {
      this.devicesCameraDeviceId = value
    },
    clickShutter (imgFile) {
      this.filesChange([imgFile])
      this.capturedImage = true
    },
  },
}
</script>

<style scoped>
.input-punto >>> input {
  -webkit-text-security: disc;
}
</style>
